import moment from 'moment';
import Communicator from 'models/communicator';
import ConversationItemType from 'models/conversation_item_type';
import { hasErrorStatus as chatHasErrorStatus } from 'models/chat_message';
import { hasErrorStatus as fbMessageHasErrorStatus } from 'models/fb_message_outgoing';
import { hasErrorStatus as smsHasErrorStatus } from 'models/sms_message';
import { hasErrorStatus as conversationMessageHasErrorStatus } from 'models/conversation_message';

export default function isItemStackable(item, previousItem) {
  return (
    item &&
    isStackableType(item) &&
    doesNotHaveError(item) &&
    previousItem &&
    doesNotHaveError(previousItem) &&
    isSameTypeOrStackableItems(item, previousItem) &&
    hasSameInitiator(item, previousItem) &&
    hasSameInitiatorType(item, previousItem) &&
    isInTimeFrame(item, previousItem) &&
    item.getIn(['initiator', 'type']) !== Communicator.AUTOMATED
  );
}

function hasSameInitiator(item, previousItem) {
  const previousInitiator = previousItem.getIn(['initiator', 'id']);
  const currentInitiator = item.getIn(['initiator', 'id']);

  return previousInitiator && currentInitiator && previousInitiator === currentInitiator;
}

function hasSameInitiatorType(item, previousItem) {
  const previousInitiator = previousItem.getIn(['initiator', 'type']);
  const currentInitiator = item.getIn(['initiator', 'type']);

  return previousInitiator && currentInitiator && previousInitiator === currentInitiator;
}

function isInTimeFrame(item, previousItem) {
  let timeLimit = moment(previousItem.get('timestamp')).add(10, 'minutes');
  return moment(item.get('timestamp')).isBefore(timeLimit);
}

function areStackableItemsForChannel(item, previousItem, channel) {
  if (channel === 'INSTAGRAM_DIRECT') {
    const itemContentType = item.getIn(['content', 'content']).get('type');
    const previousItemContentType = previousItem.getIn(['content', 'content']).get('type');

    // always add a routing line before direct video or image messages
    if (itemContentType === 'FILE' || itemContentType === 'IMAGE') {
      return false;
    }

    if (itemContentType === 'TEXT') {
      const itemMeta = item.getIn(['content', 'meta']);
      // always add a routing line before story mentions and story replies
      if (itemMeta.get('storyMentionUrl') !== undefined || itemMeta.get('storyReplyUrl') !== undefined) {
        return false;
      }
      // separate a plain text message from previous story mentions or story replies
      if (previousItemContentType === 'TEXT') {
        const prevItemMeta = previousItem.getIn(['content', 'meta']);
        return prevItemMeta.get('storyMentionUrl') === undefined && prevItemMeta.get('storyReplyUrl') === undefined;
      }
    }

    return itemContentType === previousItemContentType;
  }

  return true;
}

function isSameTypeOrStackableItems(item, previousItem) {
  if (item.getIn(['content', 'type']) === ConversationItemType.CONVERSATION_MESSAGE) {
    return (
      item.getIn(['content', 'type']) === previousItem.getIn(['content', 'type']) &&
      item.getIn(['content', 'channel']) === previousItem.getIn(['content', 'channel']) &&
      areStackableItemsForChannel(item, previousItem, item.getIn(['content', 'channel']))
    );
  }

  if (item.getIn(['content', 'type']) === ConversationItemType.CUSTOM_CHANNEL_MESSAGE) {
    return (
      item.getIn(['content', 'type']) === previousItem.getIn(['content', 'type']) &&
      item.getIn(['content', 'groupId']) === previousItem.getIn(['content', 'groupId'])
    );
  }
  return item.getIn(['content', 'type']) === previousItem.getIn(['content', 'type']);
}

const STACKABLE_TYPES = [
  ConversationItemType.CHAT_MESSAGE,
  ConversationItemType.CONVERSATION_MESSAGE,
  ConversationItemType.CONVERSATION_NOTE,
  ConversationItemType.CUSTOM_CHANNEL_MESSAGE,
  ConversationItemType.FB_MESSAGE_INCOMING,
  ConversationItemType.FB_MESSAGE_OUTGOING,
  ConversationItemType.MESSAGE_AUTOMATION_MESSAGE,
  ConversationItemType.SMS,
];

function isStackableType(item) {
  let type = item.getIn(['content', 'type']);
  return STACKABLE_TYPES.indexOf(type) >= 0;
}

function doesNotHaveError(item) {
  return !(
    (item.getIn(['content', 'type']) === ConversationItemType.SMS &&
      smsHasErrorStatus(item.getIn(['content', 'status', 'value']))) ||
    (item.getIn(['content', 'type']) === ConversationItemType.FB_MESSAGE_OUTGOING &&
      fbMessageHasErrorStatus(item.getIn(['content', 'response', 'status']))) ||
    (item.getIn(['content', 'type']) === ConversationItemType.CHAT_MESSAGE &&
      chatHasErrorStatus(item.getIn(['content', 'status', 'value']))) ||
    (item.getIn(['content', 'type']) === ConversationItemType.CONVERSATION_MESSAGE &&
      conversationMessageHasErrorStatus(item.getIn(['content', 'status', 'value'])))
  );
}
