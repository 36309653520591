import BeginLogIn from 'actions/user/begin_log_in';
import connect from 'components/lib/connect';
import NavigateToForgotPassword from 'actions/user/navigate_to_forgot_password';
import UserLogin from './user_login';

const mapStateToProps = function(context, props) {
  const credentialProvider = context.getProvider('userCredential');
  const currentLocationProvider = context.getProvider('currentLocation');

  return {
    errors: credentialProvider.getErrors(),
    isResponsePending: credentialProvider.isPending(),
    requestedUrl: currentLocationProvider.get().preAuthPath?.path,
  };
};

const mapExecuteToProps = function(executeAction, props) {
  return {
    onLogin: (email, password) => executeAction(BeginLogIn, { email, password }),
    onForgotPassword: email => executeAction(NavigateToForgotPassword, email),
  };
};

export default connect(mapStateToProps, mapExecuteToProps)(UserLogin);
