import _ from 'lodash';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Input from 'components/common/input';
import Link from 'components/common/link';

export default class PasswordField extends React.Component {
  constructor(props) {
    super(props);
    _.bindAll(this, ['onChange', 'renderPasswordInput', 'toggleShowPassword']);
    this.state = { showPassword: false };
  }

  render() {
    let classNames = classnames(
      'fa fa-lg ',
      { 'password-eye-open': !this.state.showPassword },
      { 'password-eye-closed': this.state.showPassword }
    );

    return (
      <div className="passwordContainer">
        {this.renderPasswordInput()}
        <Link className="passwordContainer-link" onClick={this.toggleShowPassword}>
          <i className={classNames} />
        </Link>
      </div>
    );
  }

  renderPasswordInput() {
    return (
      <Input
        autoComplete="off"
        autoFocus={this.props.autoFocus}
        className={classnames('passwordField', this.props.className, {
          'passwordField-text': this.state.showPassword,
          'passwordField-password': !this.state.showPassword,
        })}
        disabled={this.props.disabled}
        onBlur={this.props.onBlur}
        onChange={this.onChange}
        onFocus={this.props.onFocus}
        onKeyDown={this.props.onKeyDown}
        onSubmit={this.props.onSubmit}
        placeholder={this.props.placeholder}
        type={this.state.showPassword ? 'text' : 'password'}
        value={this.props.value}
      />
    );
  }

  onChange(evt) {
    this.props.onChange(evt.target.value);
  }

  toggleShowPassword(ev) {
    ev.stopPropagation();
    this.setState({ showPassword: !this.state.showPassword });
  }

  obscure() {
    this.setState({ showPassword: false });
  }
}

PasswordField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

PasswordField.defaultProps = {
  placeholder: 'Password',
  onKeyDown: _.noop,
};
