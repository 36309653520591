import ActivateUser from 'actions/user/activate_user';
import BeginLogOut from 'actions/user/begin_log_out';
import connect from 'components/lib/connect';
import UserActivation from './user_activation';
import UserCredentialType from 'models/user_credential_type';
import ValidateUser from 'actions/user/validate_user';

function mapStateToProps({ getProvider }) {
  const currentAgent = getProvider('currentAgent').get();
  const userCredentialProvider = getProvider('userCredential');

  return {
    currentAgentId: currentAgent?.id ?? '',
    errors: userCredentialProvider.getErrors(),
    isResponsePending: userCredentialProvider.isPending(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onActivate: ({ id, name, password }) => executeAction(ActivateUser, { id, name, password }),
    onAttributesChange: ({ name, password }) =>
      executeAction(ValidateUser, { userCredentialType: UserCredentialType.ACTIVATION_TOKEN, name, password }),
    onContinue: () => executeAction(BeginLogOut),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(UserActivation);
