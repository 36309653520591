export default class SendKeypadDigits {
  constructor(context) {
    this.context = context;
  }

  run(digits) {
    const ishttpPhoneGatewayEnabled = this.context.gateways.phoneGatewayHttp.isEnabled;
    if (ishttpPhoneGatewayEnabled) {
      this.context.gateways.phoneGatewayHttp.sendDigits(digits);
    } else {
      this.context.gateways.phoneGateway.sendDigits(digits);
    }
  }
}
