import 'dom4'; //  polyfill for classList on SVG mainly
import 'setimmediate'; // polyfill for setImmediate()

import _ from 'lodash';
import moreartyCtx from 'scripts/infrastructure/morearty_context';
import ReactDOM from 'react-dom';

// bundle i18n
import i18n from './i18n/i18n'; // eslint-disable-line no-unused-vars

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);

import qconsole from 'scripts/lib/qconsole';
window.addEventListener('error', () => {
  // when an error occurs, log the last 30s of events sent to the console so
  // that fullstory and sentry get the logs
  qconsole.tail({ since: 30 });
});
window.qconsole = qconsole;
import ErrorReporter from 'scripts/infrastructure/error_reporter';

import configureLogging from './configuration/configure_logging';
configureLogging();

import getAnalyticsAdapters from 'scripts/lib/analytics/get_analytics_adapters';
import analytics from 'scripts/lib/analytics';
if (!analytics.isConfigured()) {
  const analyticsAdapters = getAnalyticsAdapters(window.gladlyConfig);
  analytics.configure(analyticsAdapters);
}

import configureCookieStore from './configuration/configure_cookie_jar';
configureCookieStore();

import configureSystemAudio from './configuration/configure_system_audio';
configureSystemAudio();

import configureFakeWebRTCConnection from './configuration/configure_fake_webrtc';
configureFakeWebRTCConnection();

import configureSystemNotificationGenerator from './configuration/configure_system_notification_generator';
configureSystemNotificationGenerator();

import configureIdGenerator from './configuration/configure_id_generator';
configureIdGenerator();

import configureSoftwareVersionService from './configuration/configure_software_version_service';
configureSoftwareVersionService();

let rootBinding = moreartyCtx.getBinding();

import configureSharedBindings from './configuration/configure_shared_bindings';
let sharedBindings = configureSharedBindings(rootBinding);

import configureStores from './configuration/configure_stores';
let stores = configureStores(rootBinding);

import configureProviders from './configuration/configure_providers';
let providers = configureProviders(stores);

import Backends, { BackendProxy } from './infrastructure/backends';
import EventRecorder from './infrastructure/event_recorder';
let eventRecorder = new EventRecorder();
let backendProxy = new BackendProxy();

// TwilioProxy is being deprecated. Will be removed once all Twilio V1 code is removed.
import { TwilioProxy } from './infrastructure/backends/twilio_proxy';
import { TwilioProxyV2 } from './infrastructure/backends/twilio_proxy_v2';
let twilioProxy = new TwilioProxy();
let twilioProxyV2 = new TwilioProxyV2();

import CommunicationSimulationProxy from './infrastructure/backends/communication_simulation_proxy';
let commSimulationProxy = new CommunicationSimulationProxy();

const IdGenerator = configureIdGenerator();
const requestorId = IdGenerator.newId();

import configurePubsubClient from './configuration/configure_pubsub_client';
let pubsubClient = configurePubsubClient(eventRecorder, requestorId);

import configureRestClient from './configuration/configure_rest_client';
let restClient = configureRestClient(eventRecorder, requestorId);

import configureGateways from './configuration/configure_gateways';
let gateways = configureGateways({
  backend: backendProxy,
  twilio: twilioProxy,
  twilioV2: twilioProxyV2,
  commSimulation: commSimulationProxy,
  eventRecorder,
  requestorId,
});

let contextAttrs = {
  _moreartyCtx: moreartyCtx,
  analytics,
  backend: backendProxy,
  errorReporter: ErrorReporter,
  gateways,
  stores,
  providers,
};

// use an ExtendedRunnerContext for actions to be able mutate / extend it
// depending on the route, e.g. current customer
// while the RunnerContext for gateway observers should be kept immutable
import ExtendedRunnerContext from './infrastructure/extended_runner_context';
let runnerContext = new ExtendedRunnerContext({
  ...contextAttrs,
  extensionPathsBinding: rootBinding.sub('extensionPaths'),
});
import configureRouter from 'scripts/configuration/configure_router';
let router = configureRouter({
  runnerContext,
  viewState: sharedBindings.viewState,
});
runnerContext.router = router;

// expose the router to the gateway observers, since they may need to redirect
// (for instance, the phone call gateway redirects to the correct conversation for the
// customer)
import RunnerContext from './infrastructure/runner_context';
let context = new RunnerContext({ ...contextAttrs, router });
import configureGatewayObservers from './configuration/configure_gateway_observers';
configureGatewayObservers(context);

import Scheduler from 'scripts/adapters/scheduler';
runnerContext.scheduler = context.scheduler = new Scheduler(context);

import configureMqttEventHandlers from './configuration/configure_mqtt_event_handlers';
let mqttEventHandlers = configureMqttEventHandlers(context);

import configureActivityObserver from 'scripts/configuration/configure_activity_observer';
configureActivityObserver(context);

import configureNetworkEventHandler from 'scripts/configuration/configure_network_event_handler';
configureNetworkEventHandler(context);

import configureHistoryEventHandlers from 'scripts/configuration/configure_history_event_handlers';
configureHistoryEventHandlers(context);

let backends = new Backends({
  backendProxy,
  pubsubClient,
  restClient,
  gateways,
  mqttEventHandlers,
  twilioProxy,
  twilioProxyV2,
  commSimulationProxy,
  eventRecorder,
  requestorId,
});
backends.initBackend();
context.backends = backends;
runnerContext.backends = backends;

import configureCapacityManager from 'scripts/configuration/configure_capacity_manager';
let capacityManager = configureCapacityManager(context);
context.capacityManager = capacityManager;
runnerContext.capacityManager = capacityManager;

import configureCommSimulation from './configuration/configure_comm_simulation';
configureCommSimulation(runnerContext, backends);

import configureAppBootstrap from 'scripts/configuration/configure_app_bootstrap';
let bootstrap = configureAppBootstrap({ moreartyCtx, runnerContext, sharedBindings });

import configureEmojis from 'scripts/configuration/configure_emojis';
configureEmojis();

// global shortcut to switch backends
import LogOut from 'actions/user/log_out';

function useBackend(backendName) {
  runnerContext.executeAction(LogOut);
  backends.switchBackend(backendName);
}

import configureWindowUnloadHandlers from './configuration/configure_window_unload_handlers';
configureWindowUnloadHandlers(runnerContext);

// set globals for debugging and messing with data in the browser console
_.extend(global, {
  backends,
  capacityManager,
  gateways,
  eventRecorder,
  get providers() {
    return runnerContext.providers;
  },
  router,
  get stores() {
    return runnerContext.stores;
  },
  useBackend,
});

// start the router so that it routes the user to the correct view
router.start();

ReactDOM.render(bootstrap, document.getElementById('app-container'));

// render the app

import startApplication from 'scripts/application/start_application';

ErrorReporter.context(function() {
  startApplication(context);
});
