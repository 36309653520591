import _ from 'lodash';

import AutoAcceptIncomingCall from 'actions/phone_call/auto_accept_incoming_call';
import AgentVoiceCapabilitiesChecker from '../configuration/lib/agent_voice_capabilities_checker';
import { checkAndResetNavigatingToNext } from 'actions/conversation/lib/conversation_workflow';
import ErrorReporter from 'scripts/infrastructure/error_reporter';
import hasLiveConnection from 'actions/phone_call/lib/has_live_connection';
import qconsole from 'scripts/lib/qconsole';
import updateConnectionState from 'actions/health/lib/update_connection_state';
import ShowIncomingCallSystemNotification from 'actions/notification/show_incoming_call_system_notification';
import ShowPhoneCallObserverNotification from 'actions/notification/show_phone_call_observer_notification';
import { TwilioDeviceStatus } from 'models/connection_states';
import { PhoneCallObserverNotificationRecipientType } from 'models/notification/phone_call_observer_notification';

export default class PhoneGatewayObserver {
  constructor(context) {
    this.context = context;
    this.updateConnectionState = updateConnectionState.bind(this, 'twilio');
    this.agentVoiceCapabilitiesChecker = new AgentVoiceCapabilitiesChecker(this.context);
  }

  handleRegisteredAgent() {}

  handleRequestError(errorDto) {
    qconsole.log(`Phone gateway error occurred: ${JSON.stringify(errorDto)}`);
    this.handlePhoneStatus(TwilioDeviceStatus.OFFLINE);
  }

  handleAccept() {}

  handleAcceptError(message) {
    ErrorReporter.reportMessage(message, {
      extra: {
        connectionState: this.context.stores.connectionState.get(),
      },
    });
  }

  handleCall() {}

  handleCancelError(message) {
    qconsole.tail({ since: 30 });
    qconsole.error(message);
  }

  handleConnect() {
    this.updateConnectionState(state => state.setConnected());
  }

  handleDeviceError() {
    // Re-evaluate our device status permissions.
    // This will ensure we still have microphone permissions.
    this.context.gateways.deviceStatus.getStatus();
  }

  handleDisconnect() {
    this.updateConnectionState(state => state.setDisconnected());
  }

  handleTwilioEnabled() {
    let connectionStates = this.context.stores.connectionState.get();
    connectionStates.setTwilioEnabled();
    this.context.stores.connectionState.set(connectionStates);
    let twilioUmatilla = this.context.stores.appFeatures.get().isEnabled('twilioUmatilla');
    const ishttpPhoneGatewayEnabled = this.context.gateways.phoneGatewayHttp.isEnabled;
    if (ishttpPhoneGatewayEnabled) {
      this.context.gateways.phoneGatewayHttp.setTwilioUmatilla(twilioUmatilla);
    } else {
      this.context.gateways.phoneGateway.setTwilioUmatilla(twilioUmatilla);
    }
  }

  handleTwilioDisabled() {
    let connectionStates = this.context.stores.connectionState.get();
    connectionStates.setTwilioDisabled();
    this.context.stores.connectionState.set(connectionStates);
  }

  handleActiveCall() {
    let currentAgent = this.context.stores.currentAgent.get();
    const ishttpPhoneGatewayEnabled = this.context.gateways.phoneGatewayHttp.isEnabled;
    const connection = ishttpPhoneGatewayEnabled
      ? this.context.gateways.phoneGatewayHttp.connection
      : this.context.gateways.phoneGateway.connection;
    let callId = _.get(connection, 'parameters.CallSid', '');
    let appFeatures = this.context.stores.appFeatures.get();

    if (!this.agentVoiceCapabilitiesChecker.canHandlePhoneCalls()) {
      return this.handleMissingVoiceCapabilities(currentAgent, callId, appFeatures);
    }

    // Need to check for both pending and committed active calls just in case the mqtt success event comes in after the event from Twilio

    // The MQTT publish success event will typically be handled first where
    // we subscribe and commit the pending active call
    let activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      let phoneCallContent = activeCall.conversationItem.content;
      if (phoneCallContent.isOutgoing()) {
        if (ishttpPhoneGatewayEnabled) {
          this.context.gateways.phoneGatewayHttp.accept();
        } else {
          this.context.gateways.phoneGateway.accept();
        }
        return;
      }

      // If the agent is a observer participant of the call && is currently in dialing state, accept the
      // call leg so the agent can join the conference
      if (phoneCallContent.isParticipantObserver(currentAgent.id) && phoneCallContent.isDialing(currentAgent.id)) {
        if (ishttpPhoneGatewayEnabled) {
          this.context.gateways.phoneGatewayHttp.accept();
        } else {
          this.context.gateways.phoneGateway.accept();
        }
        this.context.executeAction(ShowPhoneCallObserverNotification, {
          message: 'You are now listening in on the call.',
          recipient: PhoneCallObserverNotificationRecipientType.OBSERVER,
        });
        return;
      }

      // Show green incoming call banner and system notification if there is an offered call by the time the phone connection is established
      // Delayed incoming call connection
      let hasLivePhoneConnection = hasLiveConnection(this.context, { activeCall });
      activeCall.setHasLiveConnection(hasLivePhoneConnection);
      this.context.stores.activeCall.set(activeCall);

      const navigatingToNext = checkAndResetNavigatingToNext(this.context);
      if (!navigatingToNext) {
        this.context.executeAction(ShowIncomingCallSystemNotification);
      }

      this.context.executeAction(AutoAcceptIncomingCall, {
        activeCall,
        currentAgentId: currentAgent.id,
      });

      return;
    }

    // it is possible that the activeCall is not committed in the store if the Twilio incoming call event is handled before the MQTT publish success event
    // in this case, we need to commit the pending active call to display the active call controls
    let pendingActiveCall = this.context.stores.activeCall.getPending();
    if (pendingActiveCall) {
      let phoneCallContent = pendingActiveCall.conversationItem.content;
      if (phoneCallContent.isOutgoing()) {
        this.context.stores.activeCall.commitPending();
        if (ishttpPhoneGatewayEnabled) {
          this.context.gateways.phoneGatewayHttp.accept();
        } else {
          this.context.gateways.phoneGateway.accept();
        }
      }
    }
  }

  handleMissingVoiceCapabilities(currentAgent, callId, appFeatures) {
    ErrorReporter.reportError(new Error('handleActiveCall: agent missing voice capabilities'), {
      extra: {
        agentId: currentAgent && currentAgent.id,
        callId,
        connectionState: this.context.stores.connectionState.get(),
        phonePreference: currentAgent && currentAgent.getVoiceConfigurationPreference(),
        microphonePermissions: appFeatures.isEnabled('microphone'),
      },
    });

    return;
  }

  handleHangup(conversationItemId, payload) {
    if (this.context.gateways.phoneGateway.isEnablingGateway) {
      this.context.gateways.phoneGateway.isEnablingGateway = false;
      this.switchGateway({ useV2Gateway: false });
    }
    if (this.context.gateways.phoneGatewayHttp.isEnablingGateway) {
      this.context.gateways.phoneGatewayHttp.isEnablingGateway = false;
      this.switchGateway({ useV2Gateway: true });
    }
  }

  handlePhoneControlsSuccess(conversationItemId, payload) {
    this.context.stores.activeCall.commitPending();
  }

  handlePhoneControlsErrors(conversationItemId, errorDto) {
    // Temporary work around for handling disconnect errors to ensure agents can always disconnect
    // from the call. Additional phone control error handling should be added in the future
    let pendingActiveCall = this.context.stores.activeCall.getPending();
    let currentAgentId = this.context.stores.currentAgent.get().id;
    let isCurrentAgentDisconnecting =
      pendingActiveCall && pendingActiveCall.conversationItem.content.isParticipantDisconnecting(currentAgentId);
    if (isCurrentAgentDisconnecting) {
      this.context.stores.activeCall.resetPending();
      return;
    }
    this.context.stores.activeCall.commitPending();

    // If the agent is a observer participant of the call, show error banner
    if (pendingActiveCall.isParticipantObserver(currentAgentId)) {
      this.context.executeAction(ShowPhoneCallObserverNotification, {
        message: 'Unable to listen in on call.',
        recipient: PhoneCallObserverNotificationRecipientType.OBSERVER,
      });
      return;
    }
  }

  handleCallQualityWarning(warningName) {
    this.handleCallQualityEvent(warningName, 'STARTED');
  }

  handleCallQualityWarningCleared(warningName) {
    this.handleCallQualityEvent(warningName, 'ENDED');
  }

  handleCallQualityEvent(warningName, status) {
    const activeCall = this.context.stores.activeCall.get();
    if (activeCall) {
      let currentAgentId = this.context.stores.currentAgent.get().id;
      let event = {
        eventType: warningName,
        status,
        agentId: currentAgentId,
      };

      this.context.gateways.phoneControlsHttp.recordQualityEvent(
        activeCall.customer.id,
        activeCall.conversationItem.id,
        event
      );
    }
  }

  /*
  Updates the Twilio connection status to be either ready, busy, or offline.
  - Status will be updated to 'ready' when device becomes available to take calls.
  - Status will be updated to 'busy' when device has an active call or is setup but not available.
  - Status will be updated to 'offline' when device is not setup or after a valid token expires
  */
  handlePhoneStatus(status) {
    if (_.keys(TwilioDeviceStatus).indexOf(status) === -1) {
      ErrorReporter.reportMessage(`could not handle browser phone status`, {
        extra: {
          status,
        },
      });
      return;
    }

    let connectionStates = this.context.stores.connectionState.get();
    connectionStates.updateTwilioStatus(status);
    this.context.stores.connectionState.set(connectionStates);
  }

  switchGateway({ useV2Gateway }) {
    const currentAgentId = this.context.stores.currentAgent.get().id;
    if (useV2Gateway) {
      this.context.gateways.phoneGateway.isEnabled = false;
      this.context.gateways.phoneGateway.deregisterAgent(currentAgentId);
      this.context.gateways.phoneGatewayHttp.registerAgent(currentAgentId);
    } else {
      this.context.gateways.phoneGatewayHttp.isEnabled = false;
      this.context.gateways.phoneGatewayHttp.deregisterAgent();
      this.context.gateways.phoneGateway.registerAgent(currentAgentId);
    }
  }

  handleGatewaySwitch({ useV2Gateway }) {
    if (useV2Gateway) {
      // if the pubsub gateway was waiting to be enabled, cancel that and do nothing since the correct gateway is already enabled.
      if (this.context.gateways.phoneGateway.isEnablingGateway) {
        this.context.gateways.phoneGateway.isEnablingGateway = false;
        return;
      }
      // wait to switch gateways until the active call is disconnected
      if (this.context.gateways.phoneGateway.connection) {
        this.context.gateways.phoneGatewayHttp.isEnablingGateway = true;
      } else {
        this.switchGateway({ useV2Gateway });
      }
    } else {
      if (this.context.gateways.phoneGatewayHttp.isEnablingGateway) {
        this.context.gateways.phoneGatewayHttp.isEnablingGateway = false;
        return;
      }
      if (this.context.gateways.phoneGatewayHttp.connection) {
        this.context.gateways.phoneGateway.isEnablingGateway = true;
      } else {
        this.switchGateway({ useV2Gateway });
      }
    }
  }
}
