import { bindCallbacks, statusText } from 'scripts/infrastructure/backends/fake_backend_http/lib/common';

export default class TwilioTokenService {
  constructor(database, pubsub) {
    this._pubsub = pubsub;
    this.getDatabase = database;
  }

  get(compAttrs, callback, path, { orgId }) {
    callback(null, { status: 200, statusText: statusText(200), response: { capabilityToken: 'fake-twilio-token' } });
  }

  getRoutes() {
    return bindCallbacks(
      {
        '/api/v1/orgs/:orgId/configuration/twilio-token/:agentId': {
          GET: this.get,
        },
        '/api/v1/orgs/:orgId/configuration/telephony-token/:agentId': {
          GET: this.get,
        },
      },
      this
    );
  }
}
