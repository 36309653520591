export class TwilioProxyV2 {
  constructor() {
    this._impl = null;
    this._backend = '';
  }

  get impl() {
    return this._impl;
  }

  set impl(impl) {
    if (this._impl) {
      this._impl.destroy && this._impl.destroy();
    }

    this._impl = impl;
  }

  // Return either the fake Twilio device or the real Twilio Device based on the backend
  createDevice(token, options) {
    if (this._backend === 'local') {
      this._impl.updateToken(token);
      this._impl.updateOptions(options);
      return this._impl;
    }
    return new this._impl(token, options);
  }

  get backend() {
    return this._backend;
  }

  set backend(backend) {
    this._backend = backend;
  }
}
