import createReactClass from 'create-react-class';
import path from 'path';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { upperFirst } from 'lodash';

import BrowserCompatibilityModal from './browser_compatibility_modal';
import DivButton from 'components/user/lib/div_button';
import Err from 'models/err';
import Input from 'components/common/input';
import ErrorDisplayMixin from 'components/lib/error_display_mixin';
import { isNotAllowedWithSSO } from 'components/user/auth_errors';
import LabelInput from 'components/lib/label_input';
import Link from 'components/common/link';
import ProgressBar from 'components/common/progress_bar';
import UserButton from './lib/user_button';

const UserLogin = createReactClass({
  mixins: [ErrorDisplayMixin],

  propTypes: {
    errors: PropTypes.arrayOf(PropTypes.instanceOf(Err)).isRequired,
    isResponsePending: PropTypes.bool,
    requestedUrl: PropTypes.string,
    onForgotPassword: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
  },

  getInitialState() {
    return { email: '', password: '' };
  },

  setFieldValue(field, value) {
    this.markAsModified(field);
    this.setState({ [field]: value });
  },

  onForgotPassword() {
    this.props.onForgotPassword(this.state.email);
  },

  onLogin(ev) {
    ev.preventDefault();
    this.resetModified();
    this.props.onLogin(this.state.email, this.state.password);
  },

  render() {
    return (
      <div>
        <ProgressBar isVisible={!!this.props.isResponsePending} />
        <div className="userAuthLayout-formContainer userLogin-layout userLogin-loginLayout">
          <BrowserCompatibilityModal />
          <form className="userAuthLayout-loginForm login-form" onSubmit={this.onLogin}>
            <div className="userAuthLayout-loginForm-logo" />
            <div className="userAuthLayout-loginMessage">
              <p>Not all heroes wear capes.</p>
              <p>Some wear headsets.</p>
            </div>
            {this.renderFormContent()}
          </form>
        </div>
      </div>
    );
  },

  renderAuthorizationErrors(errors) {
    let errorMessages = null;
    let nonAttrErrors = errors.filter(err => !err.attr);
    if (nonAttrErrors.length > 0 && this.state.showErrors) {
      errorMessages = this.renderErrorMessages(nonAttrErrors);
    }
    return <div className="userAuthLayout-error">{errorMessages}</div>;
  },

  renderErrorMessages(errors) {
    return errors.map((err, index) => {
      let errorString = err.detail;
      return <span key={`error-${index}`}>{upperFirst(errorString)}</span>;
    });
  },

  renderEmail() {
    return (
      <LabelInput
        errorString={this.getErrorText('username', 'email')}
        inputId="email"
        labelClass="userAuthLayout-form-inputLabel"
      >
        <Input
          autoFocus
          className="userAuthLayout-form-input email-control"
          name="email"
          onChange={evt => this.setFieldValue('email', evt.target.value)}
          placeholder="Email"
          required="required"
          value={this.state.email}
        />
      </LabelInput>
    );
  },

  renderFormContent() {
    const errors = this.props.errors;

    if (errors?.length && isNotAllowedWithSSO(errors[0])) {
      const gladlyUrl = errors[0].meta?.gladlyUrl;
      const relUrl = this.props.requestedUrl;
      const loginUrl = (gladlyUrl && relUrl && normalizeURL(gladlyUrl + relUrl)) || gladlyUrl || relUrl || '/';

      return [
        <StandaloneError data-aid="userAuthLayout-form-error" key="error-message">
          Single sign-on is required for your organization.
        </StandaloneError>,
        <div className="form-group" key="button">
          <a className="externalLink" data-aid="userAuthLayout-form-sso" href={loginUrl}>
            <DivButton>Use Single Sign-On</DivButton>
          </a>
        </div>,
      ];
    }

    return [
      this.renderAuthorizationErrors(errors),
      this.renderEmail(),
      this.renderPassword(),
      this.renderLoginButton(),
    ];
  },

  renderPassword() {
    return (
      <div className="passwordContainer">
        <LabelInput
          errorString={this.getErrorText('password')}
          inputId="password"
          labelClass="userAuthLayout-form-inputLabel"
        >
          <input
            autoComplete="off"
            className="userAuthLayout-form-input password-control"
            onChange={evt => this.setFieldValue('password', evt.target.value)}
            placeholder="Password"
            required="required"
            type="password"
            value={this.state.password}
          />
        </LabelInput>
        <Link className="forgotPassword-Link" id="forgot-password" onClick={this.onForgotPassword}>
          Forgot?
        </Link>
      </div>
    );
  },

  renderLoginButton() {
    return (
      <div className="form-group">
        <UserButton
          data-aid="userAuthLayout-form-loginButton"
          disabled={this.props.isResponsePending}
          onClick={this.onLogin}
        >
          LOGIN
        </UserButton>
      </div>
    );
  },
});

function normalizeURL(urlStr) {
  const parsed = URL.parse(urlStr);
  if (!parsed) {
    return null;
  }

  parsed.pathname = path.join(parsed.pathname);

  return parsed.toString();
}

const StandaloneError = styled.div`
  background-color: ${p => p.theme.colors.red100};
  border: ${p => p.theme.borderRadius.xsmall} solid ${p => p.theme.colors.red300};
  border-radius: ${p => p.theme.borderRadius.small};
  margin-bottom: 20px;
  margin-top: 25px;
  width: 455px;
  padding: 15px;
  text-align: center;
`;

export default UserLogin;
