import { AgentAvailability } from 'models/agent_status';
import ConversationItemType from 'models/conversation_item_type';
import { setAgentBusy } from 'actions/agent_status/lib/agent_status';
import SystemNotificationGenerator from 'scripts/domain/contracts/system_notification_generator';
import VerifyNewCustomer from 'actions/customer_match/verify_new_customer';

export default class AcceptIncomingCall {
  constructor(context) {
    this.context = context;
  }

  run() {
    let activeCall = this.context.stores.activeCall.get();
    if (!activeCall) {
      return;
    }

    const customerId = activeCall.customer.id;
    const conversationItemId = activeCall.conversationItem.id;

    if (!activeCall.customer.isVerified()) {
      this.context.executeAction(VerifyNewCustomer, {
        customerId,
      });
    }

    activeCall.setAgentHasAcceptedCall();
    this.context.stores.activeCall.set(activeCall);

    if (this.context.stores.agentStatus.get().getAvailability() !== AgentAvailability.BUSY) {
      setAgentBusy(this.context);
    }

    // check is current agent is observer on call
    let currentAgent = this.context.stores.currentAgent.get();
    if (activeCall.conversationItem.content.isParticipantObserver(currentAgent.id)) {
      this.context.gateways.phoneControlsHttp.observerAcceptTransfer(customerId, conversationItemId, {
        participantId: currentAgent.id,
      });
    } else {
      const ishttpPhoneGatewayEnabled = this.context.gateways.phoneGatewayHttp.isEnabled;
      if (ishttpPhoneGatewayEnabled) {
        this.context.gateways.phoneGatewayHttp.accept();
      } else {
        this.context.gateways.phoneGateway.accept();
      }
    }

    SystemNotificationGenerator.closeNotification(activeCall.conversationItem.id);

    this.context.analytics.track('Incoming Communication - Accepted', {
      contentType: ConversationItemType.PHONE_CALL,
      conversationItemId: activeCall.conversationItem.id,
    });
  }
}
