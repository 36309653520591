import _ from 'lodash';

import AdminNotification from 'models/notification/admin_notification';
import AgentActivity from 'models/agent_activity';
import AgentSubscriptions from './lib/agent_subscriptions';
import Boot from 'models/location/boot';
import ChangePollingInterval from 'actions/inbox/agent/change_polling_interval';
import createBrowserCapabilities from 'scripts/domain/factories/configuration/create_browser_capabilities';
import FetchEmailSuggestions from 'actions/email_suggestions/fetch_email_suggestions';
import FetchGreetingSuggestions from 'actions/greeting_suggestions/fetch_greeting_suggestions';
import FetchRecentlyMentionedAgentIds from 'actions/agent_mentions/fetch_recently_mentioned_agent_ids';
import GatewayErrorSilentHandler from 'scripts/application/lib/gateway_error_silent_handler';
import HandleCustomerReconnect from 'actions/customer/handle_customer_reconnect';
import mixin from 'scripts/lib/mixin';
import RequestAgentExperienceConfig from 'actions/agent_experience/request_agent_experience_config';
import RequestAgentNotifications from 'actions/agent_notifications/request_agent_notifications';
import RequestCustomChannelsConfig from 'actions/custom_channels/request_custom_channels_config';
import RequestOrganization from 'actions/organization/request_organization';
import { setAgentBusy } from 'actions/agent_status/lib/agent_status';
import SetAndLogActivity from 'actions/agent_activity/set_and_log_activity';
import UserActivation from 'models/location/user_activation';
import UserLogin from 'models/location/user_login';
import UserResetPassword from 'models/location/user_reset_password';

import { AgentInboxPollId } from 'actions/inbox/agent/agent_inbox_constants';
import CancelAgentInboxPolling from 'actions/inbox/agent/cancel_agent_inbox_polling';
import RequestAgentInbox from 'actions/inbox/agent/request_agent_inbox';
import RequestAgentInboxDeprecated from 'actions/inbox/agent/request_agent_inbox_deprecated';
import RequestFavoriteAnswers from 'actions/favorite_answers/request_favorite_answers';

export default class FeatureSetGatewayObserver {
  constructor(context) {
    this.context = context;
    this.agentSubscriptions = new AgentSubscriptions(this.context);
  }

  updateAppFeatures(dto) {
    let store = this.context.stores.appFeatures;
    let currentAppFeatures = store.get();

    let newGatekeeperFeatures = _.clone(dto);
    let newAppFeatures = currentAppFeatures.replace('gatekeeperFeatures', newGatekeeperFeatures);
    newAppFeatures = newAppFeatures.replace('browserCapabilities', {
      ...currentAppFeatures.browserCapabilities,
      ...createBrowserCapabilities(),
    });
    store.set(newAppFeatures);

    this.handleMqttServerStateChange(currentAppFeatures, newAppFeatures);
    this.handleDemoModeChange(currentAppFeatures, newAppFeatures);
    this.handleWebRtcChange(currentAppFeatures, newAppFeatures);
    this.handleAnalytics(newAppFeatures);
    this.handleInboxPollingIntervalChange(currentAppFeatures, newAppFeatures);
    this.handleKeepAliveChange(currentAppFeatures, newAppFeatures);
    this.handleAgentInboxChange(currentAppFeatures, newAppFeatures);
    this.handleVoiceSDKV2Change(currentAppFeatures, newAppFeatures);

    if (currentAppFeatures.gatekeeperFeatures) {
      // it's an update if gatekeeper features already exist
      this.context.router.refresh();
      return;
    }

    let auth = this.context.stores.auth.get();
    if (!auth.isUserActivated()) {
      this.continueToUserActivation();
      return;
    }

    if (auth.isPasswordBeingReset()) {
      this.continueToPasswordReset();
      return;
    }

    this.completeStartup();
  }

  handleAgentInboxChange(currentAppFeatures, newAppFeatures) {
    if (
      newAppFeatures.isEnabled('customerListAssignmentBroadcast') &&
      !currentAppFeatures.isEnabled('customerListAssignmentBroadcast')
    ) {
      const isCurrentlyPollingInbox = this.context.scheduler.has(AgentInboxPollId);
      this.context.executeAction(CancelAgentInboxPolling);
      if (isCurrentlyPollingInbox) {
        this.context.executeAction(RequestAgentInbox);
      }
    } else if (
      !newAppFeatures.isEnabled('customerListAssignmentBroadcast') &&
      currentAppFeatures.isEnabled('customerListAssignmentBroadcast')
    ) {
      const isCurrentlyPollingInbox = this.context.scheduler.has(AgentInboxPollId);
      this.context.executeAction(CancelAgentInboxPolling);
      if (isCurrentlyPollingInbox) {
        this.context.executeAction(RequestAgentInboxDeprecated);
      }
    }
  }

  handleDemoModeChange(currentAppFeatures, newAppFeatures) {
    if (newAppFeatures.isEnabled('demoMode') && !currentAppFeatures.isEnabled('demoMode')) {
      if (currentAppFeatures.gatekeeperFeatures) {
        // it's an update if gatekeeper features already exist
        this.notifyAgent('Reload your window to enter demo mode (press CTRL+R or \u2318+R)');
      } else {
        this.context.backends.enableDemoMode();
      }
    } else if (!newAppFeatures.isEnabled('demoMode') && currentAppFeatures.isEnabled('demoMode')) {
      this.notifyAgent('Reload your window to exit demo mode (press CTRL+R or \u2318+R)');
    }
  }

  handleWebRtcChange(currentAppFeatures, newAppFeatures) {
    if (!this.isInternalAgentActionsEnabled()) {
      return;
    }

    if (newAppFeatures.isEnabled('webRTC') && !currentAppFeatures.isEnabled('webRTC')) {
      this.context.gateways.deviceStatus.getStatus();
    } else if (!newAppFeatures.isEnabled('webRTC') && currentAppFeatures.isEnabled('webRTC')) {
      if (this.context.gateways.phoneGatewayHttp.isEnabled) {
        this.context.gateways.phoneGatewayHttp.deregisterAgent();
      } else {
        this.context.gateways.phoneGateway.deregisterAgent(this.getUserId());
      }
    }
  }

  handleVoiceSDKV2Change(currentAppFeatures, newAppFeatures) {
    // skip on first load of page. Enabling the correct gateway is handled in handleWebRtcChange
    if (!currentAppFeatures.sourcesAreLoaded()) {
      return;
    }
    if (!this.isInternalAgentActionsEnabled() || !newAppFeatures.isEnabled('webRTC')) {
      return;
    }

    if (newAppFeatures.isEnabled('twilioVoiceSDKV2') && !currentAppFeatures.isEnabled('twilioVoiceSDKV2')) {
      this.context.gateways.phoneGatewayHttp.enableGateway();
    } else if (!newAppFeatures.isEnabled('twilioVoiceSDKV2') && currentAppFeatures.isEnabled('twilioVoiceSDKV2')) {
      this.context.gateways.phoneGateway.enableGateway();
    }
  }

  handleAnalytics(newAppFeatures) {
    if (newAppFeatures.isEnabled('disableAnalytics')) {
      this.context.analytics.disableDefault();
    } else {
      this.context.analytics.enableDefault();
    }
  }

  handleInboxPollingIntervalChange(oldAppFeatures, newAppFeatures) {
    const isEnabled1 = newAppFeatures.isEnabled('doubleInboxPollingInterval1');
    const isEnabled2 = newAppFeatures.isEnabled('doubleInboxPollingInterval2');
    if (isEnabled1 !== oldAppFeatures.isEnabled('doubleInboxPollingInterval1')) {
      this.context.executeAction(ChangePollingInterval);
    } else if (isEnabled2 !== oldAppFeatures.isEnabled('doubleInboxPollingInterval2')) {
      this.context.executeAction(ChangePollingInterval);
    }
  }

  handleKeepAliveChange(oldAppFeatures, newAppFeatures) {
    let multiplier = 1;
    if (newAppFeatures.isEnabled('doubleKeepAlive1')) {
      multiplier *= 2;
    }
    if (newAppFeatures.isEnabled('doubleKeepAlive2')) {
      multiplier *= 2;
    }
    if (newAppFeatures.isEnabled('tripleKeepAlive')) {
      multiplier *= 3;
    }

    this.context.backend.multiplyDefaultMqttKeepAlive(multiplier);
  }

  handleMqttServerStateChange(oldAppFeatures, newAppFeatures) {
    this.context.backend.configureMqttServerV1(newAppFeatures.isEnabled('mqttServerV1'));
  }

  notifyAgent(message) {
    this.context.stores.notifications.add(AdminNotification.create({ message }));
  }

  continueToPasswordReset() {
    this.context.router.navigateTo(UserResetPassword.create());
  }

  continueToUserActivation() {
    this.context.router.navigateTo(UserActivation.create());
  }

  isImpersonatedUser() {
    let auth = this.context.stores.auth.get();
    return auth && !!auth.claims.saganUser;
  }

  completeStartup() {
    this.subscribeToModels();
    let currentLocation = this.context.stores.currentLocation.get();
    if (currentLocation instanceof UserLogin || currentLocation.isFromSso || this.isImpersonatedUser()) {
      this.completeLogin();
    }

    if (currentLocation instanceof UserLogin) {
      this.navigateToPreAuthPath(currentLocation);
    } else if (currentLocation instanceof Boot) {
      this.completeBoot(currentLocation);
    }

    this.context.capacityManager.start();
  }

  subscribeToModels() {
    this.subscribeToOrgModels();
    if (this.isInternalAgentActionsEnabled()) {
      this.agentSubscriptions.subscribe(this.getUserId());
    } else if (this.isReportBuilderEnabled() || this.isViewReportsEnabled()) {
      this.context.gateways.currentAgentProfile.request();
    }
    this.context.backends.onReconnect(() => {
      this.context.executeAction(HandleCustomerReconnect);
    });
  }

  // Org models are used throughout the app (i.e. not tied to a specific view or
  // customer, only the current organization).
  subscribeToOrgModels() {
    this.context.gateways.agent.requestAll();
    this.context.stores.agents.setLoading();

    this.context.executeAction(RequestAgentExperienceConfig);
    this.context.executeAction(RequestCustomChannelsConfig);

    this.context.gateways.agentStatusReason.requestAll();

    this.context.gateways.endpoint.requestAll();
    this.context.gateways.communicationQueue.requestAll();
    this.context.gateways.companyProfile.request();
    this.context.gateways.diagnosticsConfiguration.request();

    this.context.stores.agentInboxItems.setLoading();
    // fetch initial language list; updated when toggling answer panel
    this.context.gateways.language.fetch();
    this.context.stores.languages.setLoading();

    if (this.isAudienceActionsEnabled()) {
      this.context.gateways.audience.requestAll();
      this.context.stores.audiences.setLoading();
    }

    if (this.isReportBuilderEnabled() || this.isViewReportsEnabled()) {
      this.context.gateways.reportBuilderConfig.request();
      this.context.stores.reportBuilderConfig.setLoading();
    }

    this.context.gateways.routingGroup.request();
    this.context.stores.routingGroups.setLoading();

    this.context.gateways.topic.request();

    if (this.isViewLiveboardsEnabled()) {
      this.context.gateways.messagingConfiguration.request();
      this.context.stores.messagingConfiguration.setLoading();
    }

    if (this.isInternalAgentActionsEnabled()) {
      this.context.gateways.compositionSubscriber.subscribeAll();
      this.context.gateways.agentAssignment.subscribeAll();

      this.context.gateways.agentAssistanceConfig.request();

      this.context.gateways.conversationWorkflowConfig.request();
      this.context.stores.conversationWorkflowConfig.setLoading();

      this.context.gateways.customerProfileDef.request();
      this.context.stores.customerProfileDef.setLoading();

      this.context.gateways.integrations.requestAll();
      this.context.stores.integrations.setLoading();

      this.context.gateways.relationshipLabels.requestAll();
      this.context.stores.relationshipLabels.setLoading();

      this.context.gateways.routingQueueItem.request();
      this.context.gateways.systemConfiguration.request();

      this.context.gateways.team.request();
      this.context.stores.teams.setLoading();

      this.context.gateways.voiceConfiguration.request();

      this.context.executeAction(RequestAgentNotifications);
    }

    if (this.isAnswersAdminEnabled() || this.isInternalAgentActionsEnabled()) {
      this.context.gateways.kbVariable.request();
    }

    // fetch topic hierarchy
    this.context.gateways.topicHierarchy.requestAll();
    this.context.stores.topicHierarchy.setLoading();

    this.context.executeAction(RequestOrganization);

    if (this.isExternalAgentActionsEnabled()) {
      // phrase suggestions are pre-fetched
      // they're a static list by org so not subscribed to
      this.context.gateways.phraseSuggestions.fetch();
      this.context.stores.phraseSuggestions.setLoading();
      this.context.gateways.vgsAccount.fetchAll();
      this.context.executeAction(FetchEmailSuggestions);
      this.context.executeAction(FetchGreetingSuggestions);
    }

    // Load available UI widgets
    if (this.isInternalAgentActionsEnabled()) {
      this.context.gateways.widgets.requestAll();
      this.context.stores.widgets.setLoading();
      this.context.executeAction(FetchRecentlyMentionedAgentIds);
    }

    if (this.isCustomChannelsEnabled()) {
      this.context.gateways.customChannels.requestAll();
    }

    if (this.isCustomAttributesEnabled() && this.isInternalAgentActionsEnabled()) {
      this.context.gateways.customAttributes.requestAll();
      this.context.stores.customAttributes.setLoading();
    }

    this.context.executeAction(RequestFavoriteAnswers);
  }

  completeLogin() {
    this.context.executeAction(SetAndLogActivity, { agentId: this.getUserId(), type: AgentActivity.Type.LOG_IN });
    setAgentBusy(this.context, this);
  }

  navigateToPreAuthPath(userLogin) {
    if (userLogin.preAuthPath) {
      if (userLogin.preAuthPath.external) {
        this.context.router.navigateToPath(userLogin.preAuthPath.path);
      } else {
        this.context.router.navigateToUrl(userLogin.preAuthPath.path);
      }
    } else {
      this.context.router.navigateHome();
    }
  }

  completeBoot(boot) {
    boot.complete();
    this.context.stores.currentLocation.set(boot);

    if (boot.requestedPath) {
      this.context.router.navigateToUrl(boot.requestedPath);
    } else {
      this.context.router.navigateHome();
    }
  }

  getUserId() {
    return this.context.stores.auth.get().getUserId();
  }

  handleFeatureSet(dto) {
    this.updateAppFeatures(dto);
  }

  handleFetchSuccess(ctx, dto) {
    this.updateAppFeatures(dto);
  }

  handleFetchError(ctx, err) {
    this.handleCommonErrors(ctx, err);
  }

  isInternalAgentActionsEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('internalAgentActions');
  }

  isCustomAttributesEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('customAttributes');
  }

  isCustomChannelsEnabled() {
    return this.context.stores.customChannelsConfig.get().enabled;
  }

  isExternalAgentActionsEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('externalAgentActions');
  }

  isReportBuilderEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('reportBuilder');
  }

  isViewLiveboardsEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('viewLiveboards');
  }

  isViewReportsEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('viewReports');
  }

  isAnswersAdminEnabled() {
    return this.context.stores.appFeatures.get().isEnabled('kbManagement');
  }

  isAudienceActionsEnabled() {
    return this.isInternalAgentActionsEnabled() || this.isAnswersAdminEnabled();
  }
}

mixin(FeatureSetGatewayObserver.prototype, GatewayErrorSilentHandler.prototype);
