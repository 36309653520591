export default class UpdateBrowserPhoneStatus {
  constructor(context) {
    this.context = context;
  }

  run() {
    let isWebRtcEnabled = this.context.stores.appFeatures.get().isEnabled('webRTC');
    if (!isWebRtcEnabled) {
      return;
    }

    let connectionStates = this.context.stores.connectionState.get();
    let storedBrowserPhoneStatus = connectionStates.getBrowserPhoneStatus();
    const ishttpPhoneGatewayEnabled = this.context.gateways.phoneGatewayHttp.isEnabled;
    let currentBrowserPhoneStatus = ishttpPhoneGatewayEnabled
      ? this.context.gateways.phoneGatewayHttp.getDeviceStatus()
      : this.context.gateways.phoneGateway.getDeviceStatus();

    // Update the store with the new device status
    if (storedBrowserPhoneStatus !== currentBrowserPhoneStatus) {
      connectionStates.updateTwilioStatus(currentBrowserPhoneStatus);
      this.context.stores.connectionState.set(connectionStates);
    }
  }
}
