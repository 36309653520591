import styled from 'styled-components';

const DivButton = styled.div`
  align-items: center;
  background-color: ${p => p.theme.colors.green400};
  color: ${p => p.theme.colors.white};
  border: none;
  border-radius: ${p => p.theme.borderRadius.small};
  display: flex;
  font-size: ${p => p.theme.fontSize.medium};
  font-weight: ${p => p.theme.fontWeight.heavy};
  height: 62px;
  justify-content: center;
  min-width: 425px;
  outline: none;
  width: 100%;
`;

export default DivButton;
