import BeginLogOut from 'actions/user/begin_log_out';
import connect from 'components/lib/connect';
import ForgotPassword from 'models/location/forgot_password';
import NavigateTo from 'actions/current_location/navigate_to';
import ResetUserPassword from 'actions/user/reset_user_password';
import UserResetPassword from './user_reset_password';

function mapStateToProps({ getProvider }) {
  let userCredentialProvider = getProvider('userCredential');
  let currentAgent = getProvider('currentAgent').get();

  return {
    currentAgentId: currentAgent?.id ?? '',
    errors: userCredentialProvider.getErrors(),
    isPasswordResetPending: userCredentialProvider.isPending(),
  };
}

function mapExecuteToProps(executeAction) {
  return {
    onNavigateToForgotPassword: () => executeAction(NavigateTo, ForgotPassword.create()),
    onNavigateToLogin: () => executeAction(BeginLogOut),
    onResetPassword: ({ id, password }) => executeAction(ResetUserPassword, { id, password }),
  };
}

export default connect(mapStateToProps, mapExecuteToProps)(UserResetPassword);
